import * as React from "react"
import { graphql } from "gatsby"
import SEO from "../../components/seo"
import PageHeader from "../../components/PageHeader"
import headerImage from "../../images/gallery/1.jpg"
import styled from "styled-components"
import { GlobalContainer, GlobalSection } from "../../components/GlobalStyle"

const StyledContainer = styled(GlobalContainer)`
  max-width: 1040px;
  margin-bottom: 200px;
  background: #fff;
  padding: 40px;
  position: relative;
  top: -180px;
  p {
    margin: 20px 0;
  }
  @media(max-width: 1000px) {
    top: -60px;
  }
`

const StyledBlogPost = styled.div`
  a {
    color: var(--main);
  }
  @media(max-width: 600px) {
    padding-top: 10px;
  }
`

export default function BlogPostTemplate({
                                           data, // this prop will be injected by the GraphQL query below.
                                         }) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  return (
    <>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description}
      />
      <PageHeader image={headerImage}>
        <span data-sal="slide-up" data-sal-delay="400" data-sal-duration="400" style={{color: 'var(--main)', zIndex: '10', textTransform: 'capitalize'}}>{frontmatter.date}</span>
        <h1 className="gallery-header" data-sal="slide-up" data-sal-delay="400" data-sal-duration="400">{frontmatter.title}</h1>
      </PageHeader>
      <GlobalSection bg={"#eee"}>
        <StyledContainer>
          <StyledBlogPost>
            <div
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </StyledBlogPost>
        </StyledContainer>
      </GlobalSection>
    </>
  )
}

export const pageQuery = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date
        slug
        title
        description
      }
    }
  }
`
